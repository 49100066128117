import React from "react";
import TagManager from "react-gtm-module";
import Ad from "./Components/ad";
import Analysis from "./Pages/analysis";
import Hr from "./Pages/hr";
import "bootswatch/dist/spacelab/bootstrap.min.css";
import "./App.css";
import Home from "./Pages/home";
const TITLE = "Weekend Chronicle";
const tagManagerArgs = {
  gtmId: "GTM-5S5K9CF",
};
interface IState {
  page: JSX.Element;
}
interface IProps {}
class App extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      page: <Home />,
    };
    TagManager.initialize(tagManagerArgs);
  }
  homeSelection() {
    this.setState({
      page: <Home />,
    });
  }
  hrSelection() {
    this.setState({
      page: <Hr />,
    });
  }
  gpsSelection() {
    this.setState({
      page: <Analysis />,
    });
  }
  render() {
    return (
      <div className="App">
        <div className="my_nav_bar">
          <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
            <a className="navbar-brand" href="#">
              {TITLE}
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarColor01"
              aria-controls="navbarColor01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarColor01">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item active">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={() => this.homeSelection()}
                  >
                    ホーム <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={() => this.hrSelection()}
                  >
                    推奨心拍数
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={() => this.gpsSelection()}
                  >
                    GPSデータ解析
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        {this.state.page}
        <div>
          <Ad />
        </div>
        <div className="footer">
          <footer>
            Copyright(c) 2020 by Tomonori Hirai, all rights reserved.
          </footer>
        </div>
      </div>
    );
  }
}

export default App;
