import { produce } from "immer";
import * as actions from "../actionTypes";
import * as CONSTANT from "../../Lib/constants";
import * as TYPE from "../../Lib/types";
const initialState: TYPE.GPS = {
  trackdata: [],
  age: CONSTANT.DEFAULT_AGE,
  resthr: undefined,
  maxhr: undefined,
  maxSpeedRest: CONSTANT.DEFAULT_REST_MAX_SPEED,
  analyzeElevationInterval: CONSTANT.DEFAULT_ANALYSIS_INTERVAL,
  allStats: undefined,
  result: undefined,
};
export const gpsReducer = (
  state: TYPE.GPS = initialState,
  action: actions.Action
): TYPE.GPS => {
  switch (action.type) {
    case actions.SET_AGE:
      return produce(state, (draft: TYPE.GPS) => {
        console.log(action.payload);
        draft.age = action.payload;
      });
    case actions.SET_RESTHR:
      return produce(state, (draft: TYPE.GPS) => {
        draft.resthr = action.payload;
      });
    case actions.SET_MAXHR:
      return produce(state, (draft: TYPE.GPS) => {
        draft.maxhr = action.payload;
      });
    case actions.SET_ELEVATION_INTERVAL:
      return produce(state, (draft: TYPE.GPS) => {
        draft.analyzeElevationInterval = action.payload;
      });
    case actions.SET_MAX_REST_SPEED:
      return produce(state, (draft: TYPE.GPS) => {
        draft.maxSpeedRest = action.payload;
      });
    case actions.SET_TRACK_DATA:
      return produce(state, (draft: TYPE.GPS) => {
        draft.trackdata = action.payload;
      });
    case actions.SET_ALL_STATS:
      return produce(state, (draft: TYPE.GPS) => {
        draft.allStats = action.payload;
      });
    case actions.SET_RESULT:
      return produce(state, (draft: TYPE.GPS) => {
        draft.result = action.payload;
      });
    default:
      return state;
  }
};
