export interface Action {
  type: string;
  payload: any;
}

export const SET_AGE = "setAge";
export type SET_AGE = typeof SET_AGE;

export const SET_RESTHR = "setRestHr";
export type SET_RESTHR = typeof SET_RESTHR;

export const SET_MAXHR = "setMaxHr";
export type SET_MAXHR = typeof SET_MAXHR;

export const SET_ELEVATION_INTERVAL = "setElevationInterval";
export type SET_ELEVATION_INTERVAL = typeof SET_ELEVATION_INTERVAL;

export const SET_MAX_REST_SPEED = "setMaxRestSpeed";
export type SET_MAX_REST_SPEED = typeof SET_MAX_REST_SPEED;

export const SET_TRACK_DATA = "setTrackData";
export type SET_TRACK_DATA = typeof SET_TRACK_DATA;

export const SET_ALL_STATS = "setAllStats";
export type SET_ALL_STATS = typeof SET_ALL_STATS;

export const SET_RESULT = "setResult";
export type SET_RESULT = typeof SET_RESULT;
