import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../Redux/Reducers/index";
import { setElevationInterval, setMaxRestSpeed } from "../Redux/Actions/gps";
import * as CONSTANT from "../Lib/constants";
import "./Styles/analyzeConditionForm.css";
interface IState {}
interface IProps {}
type ReduxType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps;
class AnalyzeConditionForm extends React.Component<ReduxType, IState> {
  private maxSpeedRest: React.RefObject<HTMLInputElement> = React.createRef<
    HTMLInputElement
  >();
  private interval: React.RefObject<HTMLInputElement> = React.createRef<
    HTMLInputElement
  >();
  intervalChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    if (!isNaN(parseInt(value))) {
      this.props.setElevationInterval(parseInt(value));
      if (this.maxSpeedRest.current) {
        this.maxSpeedRest.current.value = value;
      }
    } else {
      this.props.setElevationInterval(undefined);
      if (this.maxSpeedRest.current) {
        this.maxSpeedRest.current.value = "";
      }
    }
  }
  maxRestSpeedChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    if (!isNaN(parseInt(value))) {
      this.props.setMaxRestSpeed(parseInt(value));
      if (this.maxSpeedRest.current) {
        this.maxSpeedRest.current.value = value;
      }
    } else {
      this.props.setMaxRestSpeed(undefined);
      if (this.maxSpeedRest.current) {
        this.maxSpeedRest.current.value = "";
      }
    }
  }
  analysisConditionInputEvent() {
    if (!this.props.analyzeElevationInterval) {
      alert("解析のための標高のステップを入力してください！");
    } else if (!this.props.maxSpeedRest) {
      alert("休憩とみなす最大移動速度を入力してください！");
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="analyze_condition_form">
          <div className="form-group">
            <label>休息時の最大速度 [m/sec]</label>
            <input
              className="form-control"
              type="text"
              ref={this.maxSpeedRest}
              placeholder={String(CONSTANT.DEFAULT_REST_MAX_SPEED)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.maxRestSpeedChanged(e)
              }
            />
            <label>標高の解析間隔 [m]</label>
            <input
              className="form-control"
              type="text"
              ref={this.interval}
              placeholder={String(CONSTANT.DEFAULT_ANALYSIS_INTERVAL)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.intervalChanged(e)
              }
            />
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.analysisConditionInputEvent()}
            >
              適用
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: AppState) => {
  return {
    analyzeElevationInterval: state.gpsReducer.analyzeElevationInterval,
    maxSpeedRest: state.gpsReducer.maxSpeedRest,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setElevationInterval: (payload: number | undefined) => {
      dispatch(setElevationInterval(payload));
    },
    setMaxRestSpeed: (payload: number | undefined) => {
      dispatch(setMaxRestSpeed(payload));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyzeConditionForm);
