import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../Redux/Reducers/index";
import XML from "../Lib/xml";
import UserInputForm from "../Components/userInputForm";
import HrZoneTalbe from "../Components/hrZone";
import "./Styles/hr.css";
interface IState {}
interface IProps {}
type ReduxType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps;

class Hr extends React.Component<ReduxType, IState> {
  render() {
    return (
      <React.Fragment>
        <div className="hrzone">
          <h1>あなたに最適な登山のペースは？</h1>
          <p>
            あなたは、登山をする際にどの様に歩くペースを決めていますか？
            特に意識してペースを決めている方は少ないのでははないでしょうか？
            経験や感覚で歩いている方が殆どだと思います。ところで、マラソンでは心拍数でペースを決めると、
            オーバーペースをある程度避ける事が可能になるのをご存知ですか？
            マラソンランナーの間では、心拍数を使って、ペースやトレーニングの強度を決めるのは広く行われています。
            実は、登山にもこの考え方を使う事ができます。
            <br />
            特に、標高3000mを越える登山になると、自覚症状が無い状態でも、
            あなたの体には高山病のサインが出ている物です。自覚症状が無い状態だと、
            標高が低いときと同じ感覚で歩けてしまう場合が多いために、オーバーペースになりがちです。
            心拍数を見ながら歩くペースを調節すると、いつも最適なペースで歩くことができるため、
            ばてたりする可能性が低くなります。
          </p>
          <img src="images/DSC02833.jpg" alt="" />
          <h2>心拍数ゾーンの決め方</h2>
          <p>
            心拍数ゾーンは人によって違います。幾つか決め方がありますが、このサイトでは2つの方法を使って心拍数ゾーンを決めています。
          </p>
          <ul>
            <li>最大心拍数に対する割合</li>
            <li>実際の心拍数の範囲に対する割合</li>
          </ul>
          <h3 className="underline">最大心拍数を知っていますか？</h3>
          <p>
            一番シンプルな方法は、最大心拍数の何パーセントかで心拍数ゾーンを決めています。
            ところで、あなたはあなたの最大心拍数をご存知ですか？
            普段、ランニングなどの運動をされる方はご存知の方もいらっしゃると思いますが、
            多くの方は自分の最大心拍数を知らない場合も多いと思います。
            その場合は、年齢から最大心拍数を推定する事ができます。
          </p>
          <h3 className="underline">あなたの心拍数の範囲は？</h3>
          <p>
            もう一つの方法が、あなたの安静時の心拍数と最大心拍数の間があなたの心拍数の範囲という事になります。
            その範囲の中の位置で心拍数ゾーンを決める事があります。
          </p>
          <h2 className="icon">基本情報</h2>
          <p>
            あなたの心拍数ゾーンを計算するために必要な情報を入力してください！
          </p>
          <ul>
            <li>年齢は必須です！</li>
            <li>
              安静時の心拍数と最大心拍数をご存じの方は入力してください（任意）
            </li>
          </ul>
          <p>
            最大心拍数の入力が無い場合は、年齢から推定した数値を使用します。
            安静時の心拍数を入力頂いた場合には実際の心拍数の範囲に対する割合から心拍数ゾーンを計算します。
            それ以外の場合は、最大心拍数に対する割化から心拍数ゾーンを計算します。
          </p>
          <UserInputForm />
          <h2 className="icon">心拍数ゾーン</h2>
          <p>
            入力された情報に基づいてあなたの心拍数ゾーンを計算します。
            登山では通常はゾーン１、ゾーン２の範囲でするのが無理のないペースと考えられています。
            ランニングなど普段の運動習慣がある方の場合、ゾーン３でもオーバーペースではない場合もありますが、
            行動時間が長くなったり、標高が高い場所で行動する場合は、ゾーン３の時間が長くなるとばてやすくなる傾向があります。
            なお、ゾーンの計算の仕方によって、心拍数の範囲が変わりますので、
            計算結果を目安に自分に適した心拍数の範囲を実際の登山で見つけていく事が大切です。
          </p>
          <HrZoneTalbe />
          <h2 className="icon">登山・ハイキング時の上限心拍数の目安</h2>
          <p>
            上の基本情報を入力して「適用」ボタンを押すと、あなたの登山における上限心拍数の推定値を計算して表示します。
            あなたの登山の歩くペースの調整に役立ててください！
          </p>
          <div v-if="$store.state.hrZone.hr">
            <h4>
              あなたの登山の推奨上限心拍数は
              <b>
                {this.props.age
                  ? XML.getHeartRate(
                      3.5,
                      false,
                      this.props.age,
                      this.props.resthr,
                      this.props.maxhr
                    )
                  : "【年齢・心拍数情報を入力してください】"}
                [bpm]
              </b>
              です
            </h4>
            <p>
              この心拍数以下での行動を推奨します。実際の登山で調整しながら上限の心拍数を調整してください！
            </p>
          </div>
          <p>
            (*)この値はあくまで経験に基づいた推定値で実際の登山やハイキングで調整する必要があります。
            なお、高齢の方や、心臓に疾患がある方、普段の運動習慣が無い方は医師などと相談されて実践してください。
            実践に際しては、あくまで自己責任で実施されるようお願い申し上げます。
            <span>
              この数値を使用して起きるいかなる問題、結果に対して、当方では一切の責任を負いません。
            </span>
          </p>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: AppState) => {
  return {
    age: state.gpsReducer.age,
    resthr: state.gpsReducer.resthr,
    maxhr: state.gpsReducer.maxhr,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Hr);
