import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../Redux/Reducers/index";
import {
  setAge,
  setRestHr,
  setMaxHr,
  setTrackData,
} from "../Redux/Actions/gps";
import * as TYPE from "../Lib/types";
import * as CONSTANT from "../Lib/constants";
import "./Styles/userInputForm.css";
//import { Type } from "typescript";
interface IState {}
interface IProps {}
type ReduxType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps;
class UserInputForm extends React.Component<ReduxType, IState> {
  private age: React.RefObject<HTMLInputElement> = React.createRef<
    HTMLInputElement
  >();
  private resthr: React.RefObject<HTMLInputElement> = React.createRef<
    HTMLInputElement
  >();
  private maxhr: React.RefObject<HTMLInputElement> = React.createRef<
    HTMLInputElement
  >();

  ageChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    if (!isNaN(parseInt(value))) {
      this.props.setAge(parseInt(value));
      if (this.age.current) {
        this.age.current.value = value;
      }
    } else {
      this.props.setAge(undefined);
      if (this.age.current) {
        this.age.current.value = "";
      }
    }
  }
  restHrChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    console.log(value);
    if (!isNaN(parseInt(value))) {
      this.props.setRestHr(parseInt(value));
      if (this.resthr.current) {
        this.resthr.current.value = value;
      }
    } else {
      this.props.setRestHr(undefined);
      if (this.resthr.current) {
        this.resthr.current.value = "";
      }
    }
  }
  maxHrChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    console.log(value);
    if (!isNaN(parseInt(value))) {
      this.props.setMaxHr(parseInt(value));
      if (this.maxhr.current) {
        this.maxhr.current.value = value;
      }
    } else {
      this.props.setMaxHr(undefined);
      if (this.maxhr.current) {
        this.maxhr.current.value = "";
      }
    }
  }
  userInfoInputEvent() {
    if (!this.props.maxhr && this.props.age) {
      this.props.setMaxHr(220 - this.props.age);
    } else if (!this.props.age) {
      alert("年齢を入力してください！");
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="user_input_form">
          <div className="form-group">
            <label>年齢（＊）必須</label>
            <input
              className="form-control"
              type="text"
              ref={this.age}
              placeholder={String(CONSTANT.DEFAULT_AGE)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.ageChanged(e)
              }
            />
            <label>安静時の心拍数 [bpm]</label>
            <input
              className="form-control"
              type="text"
              ref={this.resthr}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.restHrChanged(e)
              }
            />
            <label>最大心拍数 [bpm]</label>
            <input
              className="form-control"
              type="text"
              ref={this.maxhr}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.maxHrChanged(e)
              }
            />
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.userInfoInputEvent()}
            >
              適用
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: AppState) => {
  return {
    age: state.gpsReducer.age,
    resthr: state.gpsReducer.resthr,
    maxhr: state.gpsReducer.maxhr,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setAge: (payload: number | undefined) => {
      dispatch(setAge(payload));
    },
    setRestHr: (payload: number | undefined) => {
      dispatch(setRestHr(payload));
    },
    setMaxHr: (payload: number | undefined) => {
      dispatch(setMaxHr(payload));
    },
    setTrackData: (payload: Array<TYPE.TrackingData>) => {
      dispatch(setTrackData(payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserInputForm);
