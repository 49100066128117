import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../Redux/Reducers/index";
import { getTrackData } from "../Redux/Actions/gps";
import "./Styles/gpsFileSelection.css";
interface IState {}
interface IProps {}
type ReduxType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps;
class GPSFileSelectionForm extends React.Component<ReduxType, IState> {
  private fileName: React.RefObject<HTMLInputElement> = React.createRef<
    HTMLInputElement
  >();
  private inputFile: React.RefObject<HTMLInputElement> = React.createRef<
    HTMLInputElement
  >();
  fileSelected(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && this.fileName.current) {
      this.fileName.current.value = e.target.files[0].name;
      this.props.getTrackData(e.target.files[0]);
    }
  }
  fileSelectionEvent() {
    if (this.inputFile.current) {
      this.inputFile.current.click();
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="gps_file_selection_form">
          <div className="form-group">
            <label>GPSデータファイル</label>
            <input
              className="form-control"
              type="text"
              ref={this.fileName}
              readOnly
            />
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.fileSelectionEvent()}
            >
              選択
            </button>
            <input
              className="no_display"
              ref={this.inputFile}
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.fileSelected(e)
              }
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: AppState) => {
  return {
    age: state.gpsReducer.age,
    resthr: state.gpsReducer.resthr,
    maxhr: state.gpsReducer.maxhr,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getTrackData: (payload: File) => {
      dispatch(getTrackData(payload));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GPSFileSelectionForm);
