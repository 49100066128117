import * as React from "react";
import { connect } from "react-redux";
import UserInputForm from "../Components/userInputForm";
import AnalyzeConditionForm from "../Components/analyzeConditionForm";
import GPSFileSelectionForm from "../Components/gpsFileSelection";
import AnalyzeResult from "../Components/analyzeResult";
import {
  setMaxHr,
  setTrackData,
  setAllStats,
  setResult,
  getDelta,
} from "../Redux/Actions/gps";
import { AppState } from "../Redux/Reducers/index";
import * as TYPE from "../Lib/types";
import * as CONSTANT from "../Lib/constants";
import XML from "../Lib/xml";
import "./Styles/analysis.css";
interface IState {}
interface IProps {}
type ReduxType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps;

class Analysis extends React.Component<ReduxType, IState> {
  analyzeAll() {
    if (!this.props.maxhr && this.props.age) {
      this.props.setMaxHr(220 - this.props.age);
    } else if (!this.props.age) {
      alert("年齢を入力してください！");
    } else if (!this.props.maxhr && this.props.age) {
      this.props.setMaxHr(220 - this.props.age);
    } else if (!this.props.age) {
      alert("年齢を入力してください！");
    } else if (this.props.maxhr) {
      const xml: XML = new XML();
      xml.setUserInput(
        this.props.age,
        this.props.resthr,
        this.props.maxhr,
        this.props.maxSpeedRest,
        this.props.analyzeElevationInterval
      );
      const delta: Array<TYPE.DeltaData> = getDelta();
      const all: TYPE.TrackingStats = xml.analyzeStatistics(
        delta,
        undefined,
        undefined,
        CONSTANT.ANALYSIS_TYPE_TOTAL
      );
      let elevation =
        parseInt(
          String(all.elevation.min / this.props.analyzeElevationInterval)
        ) * this.props.analyzeElevationInterval;
      const result: Array<TYPE.TrackingStats> = [];
      while (all.elevation.max > elevation) {
        const sectionStats: TYPE.TrackingStats = xml.analyzeStatistics(
          delta,
          elevation,
          elevation + this.props.analyzeElevationInterval,
          CONSTANT.ANALYSIS_TYPE_TOTAL
        );
        result.push(sectionStats);
        elevation += this.props.analyzeElevationInterval;
      }
      this.props.setAllStats(all);
      this.props.setResult(result);
    }
  }
  analyzeAscend() {
    const xml: XML = new XML();
    if (this.props.age && this.props.maxhr) {
      xml.setUserInput(
        this.props.age,
        this.props.resthr,
        this.props.maxhr,
        this.props.maxSpeedRest,
        this.props.analyzeElevationInterval
      );
      const delta: Array<TYPE.DeltaData> = getDelta();
      const all: TYPE.TrackingStats = xml.analyzeStatistics(
        delta,
        undefined,
        undefined,
        CONSTANT.ANALYSIS_TYPE_TOTAL
      );
      let elevation =
        parseInt(
          String(all.elevation.min / this.props.analyzeElevationInterval)
        ) * this.props.analyzeElevationInterval;
      const result: Array<TYPE.TrackingStats> = [];
      while (all.elevation.max > elevation) {
        const sectionStats: TYPE.TrackingStats = xml.analyzeStatistics(
          delta,
          elevation,
          elevation + this.props.analyzeElevationInterval,
          CONSTANT.ANALYSIS_TYPE_ASCEND
        );
        result.push(sectionStats);
        elevation += this.props.analyzeElevationInterval;
      }
      this.props.setAllStats(all);
      this.props.setResult(result);
    }
  }
  analyzeDescend() {
    const xml: XML = new XML();
    if (this.props.age && this.props.maxhr) {
      xml.setUserInput(
        this.props.age,
        this.props.resthr,
        this.props.maxhr,
        this.props.maxSpeedRest,
        this.props.analyzeElevationInterval
      );
      const delta: Array<TYPE.DeltaData> = getDelta();
      const all: TYPE.TrackingStats = xml.analyzeStatistics(
        delta,
        undefined,
        undefined,
        CONSTANT.ANALYSIS_TYPE_TOTAL
      );
      let elevation =
        parseInt(
          String(all.elevation.min / this.props.analyzeElevationInterval)
        ) * this.props.analyzeElevationInterval;
      const result: Array<TYPE.TrackingStats> = [];
      while (all.elevation.max > elevation) {
        const sectionStats: TYPE.TrackingStats = xml.analyzeStatistics(
          delta,
          elevation,
          elevation + this.props.analyzeElevationInterval,
          CONSTANT.ANALYSIS_TYPE_DESCEND
        );
        result.push(sectionStats);
        elevation += this.props.analyzeElevationInterval;
      }
      this.props.setAllStats(all);
      this.props.setResult(result);
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="analysis">
          <h1>あなたの登山データを解析します！</h1>
          <p>
            あなたの登山のGPSデーターと心拍数を基にデーターを解析します。
            ランニング用を始めとする小型のGPS機器が普及して、
            登山でも自分が歩いたコースを簡単に記録できるようになりました。
            さらに、登山中の心拍数のデータも取れるようになったので、
            登山中のペースの管理や解析が簡単にできるようになりました。
            このサイトでは、あなたの登山のGPSデーターを解析して、
            あなたの登山中のデータを解析するサービスを提供しています。
            <br />
            このデータは富士山など3000mを超える山に登る場合に役に立ちます。
            高山病を避ける事はできませんが、その影響を最小限にすることも可能になります。
          </p>
          <img src="./images/DSC02833.jpg" alt="" />
          <h2>登山のGPSのデータ解析</h2>
          <p>
            あなたの登山時のGPSのデータを解析します。
            実際にあなたが登山した時のデータからあなたの歩行ペースが無理のないものであったか、
            高山病の影響が出ていたのかなどを解析して、
            将来の登山に役立てる事ができます。
            心拍数を登山やハイキング中にモニタ出来る機器をお持ちでない方は、
            これを機会に是非心拍計を手に入れてより快適な登山、
            ハイキングを楽しんでみてはいかがでしょうか？
            お勧めの機器ののページでは予算と用途に応じた心拍数をはかるための機器を紹介しています。
            <br />
            ご利用方法は簡単で、あなたの登山のGPSデータ（TCX形式かGPX形式）のデータを読み込んで、
            下の情報を入力してください。
            当サイトはスマートフォンでのご利用も可能ですが、
            GPSのデータファイルをスマートフォンにダウンロードして、
            下のフォームから選択可能である必要があります。
            ご利用の機種やOSのバージョンによってご利用になれない事もあります。予めご了承ください。
          </p>
          <h3 className="icon">GPSのデータ</h3>
          <p>
            GPSのトラッキングデータのファイルを指定してください。
            <br />
            ガーミンコネクトからダウンロードできるTCX形式のファイルとGPX形式のファイルをサポートしています。
            また、StravaやRunkeeperからダウンロードできるGPX形式のファイルも読み込むことが可能です。
            ガーミン以外のGPS機器をご利用の方は、こうしたGPSのデータサービスをご利用ください。
          </p>
          <GPSFileSelectionForm />
          <h3 className="icon">利用者情報</h3>
          <p>
            あなたの年齢（必須）を入力してください。
            もし、安静時の心拍数や、実際の最大心拍数をご存知の場合入力してください。
            より正確な解析が可能になります。
            ご存知ない場合は、あなたの年齢から最大心拍数を推定して解析します。
          </p>

          <UserInputForm />
          <h3 className="icon">解析条件</h3>
          <p>
            解析条件として、休息の条件と標高の間隔[m]を入力してください。
            ツールの設定では1秒で一歩以下（0.3m/sec）のデータを休息とみなして標高を100mごとに区切ってデータを処理しています。
          </p>
          <AnalyzeConditionForm />
          <h3 className="icon">データ解析</h3>
          <p>
            データの解析を行う条件のボタンを押してください。
            ボタンを押す前に、GPSのデータファイルを読み込んで、解析条件を入力してください。
            解析条件のうち、安静時の心拍数と最大心拍数は任意ですが、年齢は必須です。
            解析は、登りの区間のみ、下りの区間ののみ、登りと下りを含む全体の解析ができます。
            登山においては、登りのデータが特に重要で、標高による、ペース（速度）や休息時間の比率を見ると、
            高山病の影響が出ているかどうかがわかります。
            データーを見る時のポイントは、昼食などで意図的に長時間休憩を取っていない状態で、
            休息時間の割合が50%前後の場合、オーバーペースでばてている場合が多くなっています。
          </p>
          <div className="analyze_button_div">
            <div className="form-group">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => this.analyzeAll()}
              >
                全体
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => this.analyzeAscend()}
              >
                登り
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => this.analyzeDescend()}
              >
                下り
              </button>
            </div>
          </div>
          <AnalyzeResult />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: AppState) => {
  return {
    age: state.gpsReducer.age,
    resthr: state.gpsReducer.resthr,
    maxhr: state.gpsReducer.maxhr,
    maxSpeedRest: state.gpsReducer.maxSpeedRest,
    analyzeElevationInterval: state.gpsReducer.analyzeElevationInterval,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setTrackData: (payload: Array<TYPE.TrackingData>) => {
      dispatch(setTrackData(payload));
    },

    setMaxHr: (payload: number | undefined) => {
      dispatch(setMaxHr(payload));
    },
    setAllStats: (payload: TYPE.TrackingStats) => {
      dispatch(setAllStats(payload));
    },
    setResult: (payload: Array<TYPE.TrackingStats>) => {
      dispatch(setResult(payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Analysis);
