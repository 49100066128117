/* ****************************************************************************
	location.js
    Copyright(c) 2016-2019 by Silicon Vallei Super Ware, all rights reserved.
    Created on Aug 21, 2019 (for Javascript)
 ******************************************************************************/
/**
 * @version 1.0
 * @author  Silicon Valley Super Ware
 */
/*
    ***************************************************************************
    Revision History
    ***************************************************************************
    ======= =============   ===================================================
    Version Date            Description
    ======= =============   ===================================================
    0.0     Feb 26, 2016    Initial implementation in Java
	1.0		Aug 21, 2019	Ported to Javascript
    ===========================================================================
*/
// ****************************************************************************
//	Constant(s)
// ****************************************************************************
/** Bessel Ellipsoid */
const BESSEL = 0;
/** Geodetic Reference system 1980 */
const GRS80 = 1;
/** World Geodetic System 1984 */
const WGS84 = 2;
/** Unit system -- metric */
//const METRIC = true;
/** Unit system -- statute */
//const STATUTE = false;
/** WGS84 (World Geodetic System 1984): Semi-major axis -- 長半径 (meter) */
const WGS84_SEMI_MAJOR_AXIS = 6378137.0;
/** WGS84 (World Geodetic System 1984): Flattening factor -- 偏平率 */
const WGS84_FLATTENING = 1 / 298.257223563;
/** Bessel Ellipsoid: Semi-major axis -- 長半径 (meter) */
const BESSEL_SEMI_MAJOR_AXIS = 6377397.155;
/** Bessel Ellipsoid: Flattening factor -- 偏平率 */
const BESSEL_FLATTENING = 1 / 299.152813;
/** GRS80 (Geodetic Reference System 1980): Semi-major axis -- 長半径 (meter) */
const GRS80_SEMI_MAJOR_AXIS = 6378137.0;
/** GRS80 (Geodetic Reference System 1980): Flattening factor -- 偏平率 */
const GRS80_FLATTENING = 1 / 298.257222101;
/** One meter in miles */
const ONE_METER_IN_MILE = 0.000621371;
/** One mile in meters */
const ONE_MILE_IN_METER = 1609.34;
/** One meter in feet */
const ONE_METER_IN_FEET = 3.28084;
/** One foot in meters */
const ONE_FOOT_IN_METER = 0.3048;

/** GPS Location class
 * 	This is a utility class to calculate distance between two points with latitudes and logitudes
 *  Methods to calculate the distance is a standard way to calculate by GPS locations
 */
class GPSLocation {
  // ************************************************************************
  //  Constructor(s)
  // ************************************************************************
  private type: number;
  private semiMajorAxis: number;
  private flattening: number;
  private label: string;
  private latitude: number;
  private longitude: number;
  private altitude: number;
  private minimumDistance: number;
  private maximumDistance: number;
  private metric: boolean;
  /** Default Constructor */
  constructor() {
    //Default method is WGS84
    /** Type of the distance calculation method */
    this.type = WGS84;
    /** Semi-major axis (default is WGS84 value) */
    this.semiMajorAxis = WGS84_SEMI_MAJOR_AXIS;
    /** Flattening  (default is WGS84 vale) */
    this.flattening = WGS84_FLATTENING;
    /** Label for the location */
    this.label = "";
    /** Latitude (default value is '0') */
    this.latitude = 0;
    /** Longitude (default value is '0') */
    this.longitude = 0;
    /** Altitude (Unit in the class is metric) */
    this.altitude = 0;
    /** Minimum Distance (Unit in the class is metric)
     *	This value is used to identify a location is considered as a specific way point
     */
    this.minimumDistance = 0;
    /** Maximum Distance (Unit in the class is metric)
     *  This value is used to identify a location is considered as a specific way point
     */
    this.maximumDistance = 0;
    /** Unit (Default is metric)*/
    this.metric = true;
  }
  // ************************************************************************
  //  Method(s)
  // ************************************************************************
  /** Gets distance between two locations
   * @param latitude  A latitude for a location (double)
   * @param longitude A longitude for a location (double)
   * @return  Returns a distance between the two locations (double) */
  getDistance(latitude: number, longitude: number): number {
    return this._getDistance(
      this.latitude,
      this.longitude,
      latitude,
      longitude
    );
  }

  /** Gets label
   * @return  Returns the label of a way point (String) */
  getLabel() {
    return this.label;
  }
  /** Gets latitude
   * @return  Returns the latitude of a way point (double) */
  getLatitude() {
    return this.latitude;
  }
  /** Gets longitude
   * @return  Returns the longitude of a way point (double) */
  getLongitude() {
    return this.longitude;
  }
  /** Gets altitude
   * @return  Returns the altitude of a way point (double) */
  getAltitude() {
    if (this.metric) {
      return this.altitude;
    } else {
      return this.altitude * ONE_METER_IN_FEET;
    }
  }
  /** Gets minimum distance
   * @return  Returns the minimum distance of the way point (double) */
  getMinimumDistance() {
    if (this.metric) {
      return this.minimumDistance;
    } else {
      return this.minimumDistance * ONE_METER_IN_MILE;
    }
  }
  /** Gets maximum distance
   * @return  Returns the maximum distance of the way point (double) */
  getMaximumDistance() {
    if (this.metric) {
      return this.maximumDistance;
    } else {
      return this.maximumDistance * ONE_METER_IN_MILE;
    }
  }
  /** Gets units
   * @return Returns the unit type. true: metric, false: statute */
  getUnit() {
    return this.metric;
  }
  /** Sets label
   * @param s A way point name (String) */
  setLabel(s: string) {
    this.label = s;
  }
  /** Sets location
   * @param latitude A latitude value of a way point (double)
   * @param longitude A latitude value of a way point (double) */
  setLocation(latitude: number, longitude: number) {
    this.latitude = latitude;
    this.longitude = longitude;
  }
  /** Set location
   * @param latitude_degrees A latitude value (degrees part) (double)
   * @param latitude_minutes A latitude value (minutes part) (double)
   * @param longitude_degrees A longitude value (degrees part) (double)
   * @param longitude_minutes A longitude value (degrees part) (double) */
  setLocation_(
    latitudeDegrees: number,
    latitudeMinutes: number,
    longitudeDegrees: number,
    longitudeMinutes: number
  ) {
    this.latitude = latitudeDegrees + latitudeMinutes / 60;
    this.longitude = longitudeDegrees + longitudeMinutes / 60;
  }
  /** Sets altitude
   * @param value A altitude value of a way point (double) */
  setAltitude(value: number) {
    this.altitude = value;
  }
  /** Sets altitude
   * @param value An altitude value of a way point (double)
   * @param unit The unit type.  true: metric, false: statute */
  setAltitude_(value: number, unit: boolean) {
    if (unit) {
      this.altitude = value;
    } else {
      this.altitude = value * ONE_FOOT_IN_METER;
    }
  }
  /** Sets minimum distance
   * @param value A minimum distance value of a way point (double) */
  SetMinimumDistance(value: number): void {
    this.minimumDistance = value;
  }
  /** Sets minimum distance
   * @param value A minimum distance value of a way point (double)
   * @param unit The unit type. true: metric, false: statute */
  SetMinimumDistanceWithUnit(value: number, unit: boolean) {
    if (unit) {
      this.minimumDistance = value;
    } else {
      this.minimumDistance = value * ONE_MILE_IN_METER;
    }
  }
  /** Sets maximum distance
   * @param value A maximum distance value of a way point (double) */
  SetMaximumDistance(value: number) {
    this.maximumDistance = value;
  }
  /** Sets maximum distance
   * @param value A maximum distance value of a way point (double)
   * @param unit The unit type. true: metric, false: statute */
  SetMaximumDistanceWithUnit(value: number, unit: boolean) {
    if (unit) {
      this.maximumDistance = value;
    } else {
      this.maximumDistance = value * ONE_MILE_IN_METER;
    }
  }
  /** Sets a geodetic reference system definition type
   * @param defType A geodetic reference system definition (integer)*/
  setDefinitionType(defType: number) {
    this.type = defType;
    switch (this.type) {
      case BESSEL:
        this.semiMajorAxis = BESSEL_SEMI_MAJOR_AXIS;
        this.flattening = BESSEL_FLATTENING;
        break;
      case GRS80:
        this.semiMajorAxis = GRS80_SEMI_MAJOR_AXIS;
        this.flattening = GRS80_FLATTENING;
        break;
      case WGS84:
        this.semiMajorAxis = WGS84_SEMI_MAJOR_AXIS;
        this.flattening = WGS84_FLATTENING;
        break;
    }
  }
  /** Sets units
   * @param unit The unit type. true: metric, false: statute (boolean)*/
  setUnit(unit: boolean) {
    this.metric = unit;
  }

  /** Gets distance -- based on Vincentry's formulae
   * @param latitude0 latigude for a point 0 (double)
   * @param longitude0 longitude for a point 0 (double)
   * @param latitude1 latigude for a point 1 (double)
   * @param longitude1 longitude for a point 1 (double)
   * @return Retuns a distance between point 0 and point 1 (double) */
  _getDistance(
    latitude0: number,
    longitude0: number,
    latitude1: number,
    longitude1: number
  ) {
    const averageLatitude = this.degreeToRadian((latitude0 + latitude1) / 2);
    const deltaLatitude = this.degreeToRadian(latitude0 - latitude1);
    const deltaLongitude = this.degreeToRadian(longitude0 - longitude1);
    const eccentricitySqr =
      2 * this.flattening - this.flattening * this.flattening;

    const w = Math.sqrt(
      1.0 -
        eccentricitySqr * Math.sin(averageLatitude) * Math.sin(averageLatitude)
    );
    const meridianRadiusOfCurvature =
      (this.semiMajorAxis * (1 - eccentricitySqr)) / (w * w * w);
    const primVerticalRadiusOfCurvature = this.semiMajorAxis / w;

    const a = deltaLatitude * meridianRadiusOfCurvature;
    const b =
      deltaLongitude *
      primVerticalRadiusOfCurvature *
      Math.cos(averageLatitude);
    /* Vincentry's formulae */
    const distance = Math.sqrt(a * a + b * b);
    return distance;
  }
  /** Converts degree to radian
   * @param degrees An angle in degrees (double)
   * @return Returns a value in radian (double) */
  degreeToRadian(degrees: number) {
    return (degrees * Math.PI) / 180.0;
  }
}
export default GPSLocation;
