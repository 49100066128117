import * as React from "react";
import AdSense from "react-adsense";

export default class Ad extends React.Component {
  render() {
    const contents = "";

    return (
      <React.Fragment>
        <AdSense.Google
          className="adsbygoogle"
          style={{ display: "block" }}
          client="ca-pub-1296311454308475"
          slot="3352296813"
          format="auto"
          responsive="true"
        />
      </React.Fragment>
    );
  }
}
