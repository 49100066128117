import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../Redux/Reducers/index";
import * as TYPE from "../Lib/types";
interface IState {}
interface IProps {}
type ReduxType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps;
class AnalyzeResult extends React.Component<ReduxType, IState> {
  // Moving distance and elevation

  render() {
    const result = this.props.result;
    if (result) {
      const ResultMovingDistanceElevation = result.map(
        (item: TYPE.TrackingStats) => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.total.distance.toFixed(2)}</td>
            <td>{item.total.ascending.toFixed(2)}</td>
            <td>{item.total.descending.toFixed(2)}</td>
          </tr>
        )
      );

      const ResultAverageHr = result.map((item: TYPE.TrackingStats) => (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>{item.average.hrMove.toFixed(2)}</td>
          <td>{item.average.hrRest.toFixed(2)}</td>
          <td>{item.average.hr.toFixed(2)}</td>
        </tr>
      ));

      const ResultMinHr = result.map((item: TYPE.TrackingStats) => (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>{item.minHR.hrMove.toFixed(2)}</td>
          <td>{item.minHR.hrRest.toFixed(2)}</td>
          <td>{item.minHR.hr.toFixed(2)}</td>
        </tr>
      ));

      const ResultMaxHr = result.map((item: TYPE.TrackingStats) => (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>{item.maxHR.hrMove.toFixed(2)}</td>
          <td>{item.maxHR.hrRest.toFixed(2)}</td>
          <td>{item.maxHR.hr.toFixed(2)}</td>
        </tr>
      ));
      const ResultHrZone = result.map((item: TYPE.TrackingStats) => (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>
            {((item.hrZone.zone0 / item.totalTime.time) * 100).toFixed(2)}
          </td>
          <td>
            {((item.hrZone.zone1 / item.totalTime.time) * 100).toFixed(2)}
          </td>
          <td>
            {((item.hrZone.zone2 / item.totalTime.time) * 100).toFixed(2)}
          </td>
          <td>
            {((item.hrZone.zone3 / item.totalTime.time) * 100).toFixed(2)}
          </td>
          <td>
            {((item.hrZone.zone4 / item.totalTime.time) * 100).toFixed(2)}
          </td>
          <td>
            {((item.hrZone.zone5 / item.totalTime.time) * 100).toFixed(2)}
          </td>
        </tr>
      ));
      const ResultMovingSpeed = result.map((item: TYPE.TrackingStats) => (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>{item.average.speedMove.toFixed(2)}</td>
          <td>{item.average.vspeedMove.toFixed(2)}</td>
        </tr>
      ));

      const ResultAverageSpeed = result.map((item: TYPE.TrackingStats) => (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>{item.average.speed.toFixed(2)}</td>
          <td>{item.average.vspeed.toFixed(2)}</td>
        </tr>
      ));

      const ResultRestingStats = result.map((item: TYPE.TrackingStats) => (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>
            {((item.totalTime.move / item.totalTime.time) * 100).toFixed(2)}
          </td>
          <td>
            {((item.totalTime.rest / item.totalTime.time) * 100).toFixed(2)}
          </td>
          <td>{(item.totalTime.time / 60).toFixed(2)}</td>
        </tr>
      ));

      const ResultAverageSlope = result.map((item: TYPE.TrackingStats) => (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>{item.average.angle.toFixed(2)}</td>
        </tr>
      ));
      return (
        <React.Fragment>
          <div>
            <div>
              <h3 className="underline">移動距離・標高差</h3>
              <p>各区間の移動距離と標高差です。</p>
              <table className="table table-sm">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">標高[m]</th>
                    <th scope="col">距離[m]</th>
                    <th scope="col">登り[m]</th>
                    <th scope="col">下り[m]</th>
                  </tr>
                </thead>
                <tbody>{ResultMovingDistanceElevation}</tbody>
              </table>
            </div>
            <div>
              <h3 className="underline">心拍数</h3>
              <p>
                心拍数のデータです。行動時（歩行時）、休息時、行動時と休息時を合わせた全体の値を計算しています。
              </p>
              <h4>平均心拍数</h4>
              <table className="table table-sm">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">標高[m]</th>
                    <th scope="col">行動時平均[bpm]</th>
                    <th scope="col">休息時平均[bpm]</th>
                    <th scope="col">全体平均[bpm]</th>
                  </tr>
                </thead>
                <tbody>{ResultAverageHr}</tbody>
              </table>
              <h4>最小心拍数</h4>
              <table className="table table-sm">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">標高[m]</th>
                    <th scope="col">行動時最小[bpm]</th>
                    <th scope="col">休息時最小[bpm]</th>
                    <th scope="col">全体最小[bpm]</th>
                  </tr>
                </thead>
                <tbody>{ResultMinHr}</tbody>
              </table>
              <h4>最大心拍数</h4>
              <table className="table table-sm">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">標高[m]</th>
                    <th scope="col">行動時最大[bpm]</th>
                    <th scope="col">休息時最大[bpm]</th>
                    <th scope="col">全体最大[bpm]</th>
                  </tr>
                </thead>
                <tbody>{ResultMaxHr}</tbody>
              </table>
            </div>
            <div>
              <h3 className="underline">心拍数ゾーン</h3>
              <p>
                心拍ゾーンの分布です。心拍数ゾーンのZ3/Z4/Z5の比率が高い場合はオーバーペースと考えられます。
              </p>
              <table className="table table-sm">
                <thead className="thead-dark">
                  <th scope="col">標高[m]</th>
                  <th scope="col">Zone 0</th>
                  <th scope="col">Zone 1</th>
                  <th scope="col">Zone 2</th>
                  <th scope="col">Zone 3</th>
                  <th scope="col">Zone 4</th>
                  <th scope="col">Zone 5</th>
                </thead>
                <tbody>{ResultHrZone}</tbody>
              </table>
            </div>
            <div>
              <h3 className="underline">移動速度</h3>
              <h4>歩行時</h4>
              <p>休息時間を除いた歩行時の平均移動速度です。</p>
              <table className="table table-sm">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">標高[m]</th>
                    <th scope="col">水平[m/sec]</th>
                    <th scope="col">垂直[m/sec]</th>
                  </tr>
                </thead>
                <tbody>{ResultMovingSpeed}</tbody>
              </table>
              <h4>全体</h4>
              <p>休息時間を考慮した全体の平均移動速度です</p>
              <table className="table table-sm">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">標高[m]</th>
                    <th scope="col">水平[m/sec]</th>
                    <th scope="col">垂直[m/sec]</th>
                  </tr>
                </thead>
                <tbody>{ResultAverageSpeed}</tbody>
              </table>
            </div>
            <div>
              <h3 className="underline">休息時間</h3>
              <p>
                予め設定した休息時の最大速度以下の時間の合計です。
                全体の所要時間に対する割合を算出しています。
                昼食時や山頂など意図的に長時間の休憩をとったところ以外で、
                休息時間の比率が40%を超える場合、オーバーペースでばてた状態である事が多くなっています。
              </p>
              <table className="table table-sm">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">標高[m]</th>
                    <th scope="col">移動[%]</th>
                    <th scope="col">休息[%]</th>
                    <th scope="col">全体[分]</th>
                  </tr>
                </thead>
                <tbody>{ResultRestingStats}</tbody>
              </table>
            </div>
            <div>
              <h3 className="underline">平均斜度</h3>
              <p>
                各区間の平均斜度を、登り、下り、全体を含めて算出しています。
              </p>
              <table className="table table-sm">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">標高[m]</th>
                    <th scope="col">平均斜度[度]</th>
                  </tr>
                </thead>
                <tbody>{ResultAverageSlope}</tbody>
              </table>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }
}
const mapStateToProps = (state: AppState) => {
  return {
    result: state.gpsReducer.result,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(AnalyzeResult);
