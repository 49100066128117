import store from "../store";
import { AppState } from "../Reducers/index";

import * as actions from "../actionTypes";
import * as TYPE from "../../Lib/types";
import XML from "../../Lib/xml";

export const setAge = (payload: number | undefined) => {
  return {
    type: actions.SET_AGE,
    payload: payload,
  };
};
export const setRestHr = (payload: number | undefined) => {
  return {
    type: actions.SET_RESTHR,
    payload: payload,
  };
};
export const setMaxHr = (payload: number | undefined) => {
  return {
    type: actions.SET_MAXHR,
    payload: payload,
  };
};
export const setElevationInterval = (payload: number | undefined) => {
  return {
    type: actions.SET_ELEVATION_INTERVAL,
    payload: payload,
  };
};
export const setMaxRestSpeed = (payload: number | undefined) => {
  return {
    type: actions.SET_MAX_REST_SPEED,
    payload: payload,
  };
};
export const setTrackData = (payload: Array<TYPE.TrackingData>) => {
  return {
    type: actions.SET_TRACK_DATA,
    payload: payload,
  };
};
export const setAllStats = (payload: TYPE.TrackingStats) => {
  return {
    type: actions.SET_ALL_STATS,
    payload: payload,
  };
};
export const setResult = (payload: Array<TYPE.TrackingStats>) => {
  return {
    type: actions.SET_RESULT,
    payload: payload,
  };
};

export const getTrackData = (payload: File) => {
  return async (disptch: any) => {
    const xml: XML = new XML();
    const trackdata: Array<TYPE.TrackingData> = await xml.readXml(payload);
    disptch(setTrackData(trackdata));
  };
};

export function getDelta() {
  const state: AppState = store.getState();
  const deltas: Array<TYPE.DeltaData> = [];
  let previous: TYPE.TrackingData = state.gpsReducer.trackdata[0];
  for (let i = 1; i < state.gpsReducer.trackdata.length; i++) {
    const current: TYPE.TrackingData = state.gpsReducer.trackdata[i];
    if (
      current.distance &&
      current.hr &&
      current.speed &&
      current.elevation &&
      previous.distance &&
      previous.hr &&
      previous.speed &&
      previous.elevation
    ) {
      const delta: TYPE.DeltaData = {
        time:
          Math.floor(current.time.getTime() / 1000) -
          Math.floor(previous.time.getTime() / 1000),
        distance: current.distance - previous.distance,
        hr: current.hr - previous.hr,
        speed: current.speed - previous.speed,
        elevation: current.elevation - previous.elevation,
        data: current,
      };
      deltas.push(delta);
    }
    previous = current;
  }
  console.log(deltas);
  return deltas;
}
