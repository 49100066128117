/******************************************************************************
	Garmin GPS data analysis tool
	Version 0.0 / August 2, 2019
	Copyright(c) 2019 by Silicon Valley Super Ware, all rights reserved.
******************************************************************************/
import * as xml2js from "xml2js";
import * as CONSTANT from "./constants";
import * as TYPE from "./types";
import GPSLocation from "./location";
// ****************************************************************************
//	XML class
// ****************************************************************************
const DEBUG: boolean = process.env.VUE_APP_DEBUG === "true" ? true : false;
const OBJECT_VALUE = 1;
const FIRST_ELEMENT = 0;
/** XML class -- basic XML reader */
class XML {
  private userInput: TYPE.UserInput;
  constructor() {
    console.log("XML");
    this.userInput = {
      age: CONSTANT.DEFAULT_AGE,
      restHr: undefined,
      maxHr: undefined,
      maxSpeedRest: CONSTANT.DEFAULT_REST_MAX_SPEED,
      elevationInterval: CONSTANT.DEFAULT_ANALYSIS_INTERVAL,
    };
  }
  setUserInput(
    age: number,
    restHR: number | undefined,
    maxHr: number,
    maxSpeedRest: number,
    elevationInterval: number
  ) {
    this.userInput = {
      age: age,
      restHr: restHR,
      maxHr: maxHr,
      maxSpeedRest: maxSpeedRest,
      elevationInterval: elevationInterval,
    };
  }
  /** Reads an XML file
   *  This methods supports "GPX" and "TCX" with "Track Point extension"
   * @param file A file object to be read (File)
   * @return Returns a Javascript object
   */
  readXml(file: File): Promise<Array<TYPE.TrackingData>> {
    return new Promise((resolve) => {
      const reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target) {
          const error: DOMException | null = e.target.error;
          if (error) {
            if (DEBUG) {
              alert("Error: " + error);
            }
            resolve([]);
          } else {
            const records: Array<any> = [];
            const contents: string | ArrayBuffer | null = e.target.result;
            let extNameSpace: string;
            xml2js.parseString(
              contents as string,
              (err: Error, result: any): void => {
                if (err) {
                  if (DEBUG) {
                    console.log(err);
                  }
                  resolve([]);
                } else {
                  if (DEBUG) {
                    console.log("XML contents:");
                    console.log(result);
                  }
                  if (result.gpx) {
                    // ========================================================
                    //  Reads GPX file
                    // ========================================================
                    if (DEBUG) {
                      console.log("This is a GPX file");
                    }
                    // Gets extension name space
                    let previous: TYPE.TrackingData | undefined = undefined;
                    const header = result.gpx.$;
                    for (const key in header) {
                      const value = header[key] as any;
                      if (
                        key.indexOf(CONSTANT.TAG_NS_EXT) === 0 &&
                        value.indexOf(CONSTANT.TAG_EXT_GPX) !== -1
                      ) {
                        // Gets a name space for track point extension
                        extNameSpace = key.split(CONSTANT.NAME_SPACE_SEPARATOR)[
                          OBJECT_VALUE
                        ];
                      }
                    }

                    const data =
                      result.gpx.trk[FIRST_ELEMENT].trkseg[FIRST_ELEMENT].trkpt;

                    for (let i = 0; i < data.length; i++) {
                      const entry = data[i];
                      //const time: Date = new Date(entry.time);
                      const extNS: string =
                        extNameSpace +
                        CONSTANT.NAME_SPACE_SEPARATOR +
                        CONSTANT.TAG_EXT_GPX;
                      const hrNS: string =
                        extNameSpace +
                        CONSTANT.NAME_SPACE_SEPARATOR +
                        CONSTANT.TAG_EXT_GPX_HR;
                      //const cadenceNS: string =
                      //  extNameSpace +
                      //  CONSTANT.NAME_SPACE_SEPARATOR +
                      //  CONSTANT.TAG_EXT_GPX_RUN_CADENCE;
                      //const tempNS: string =
                      //  extNameSpace +
                      //  CONSTANT.NAME_SPACE_SEPARATOR +
                      //  CONSTANT.TAG_EXT_GPX_TEMP;
                      const record: TYPE.TrackingData = {
                        time: new Date(entry.time),
                        elevation: parseFloat(entry.ele[FIRST_ELEMENT]),
                        location: {
                          latitude: parseFloat(entry.$.lat),
                          longitude: parseFloat(entry.$.lon),
                        },
                        hr: parseFloat(
                          entry.extensions[FIRST_ELEMENT][extNS][FIRST_ELEMENT][
                            hrNS
                          ][FIRST_ELEMENT]
                        ),
                        distance: 0, // Place holder only
                        speed: undefined,
                        runcadence: undefined,
                        temperature: undefined,
                        /*
                        runcadence: parseFloat(
                          entry.extensions[FIRST_ELEMENT][extNS][FIRST_ELEMENT][
                            cadenceNS
                          ][FIRST_ELEMENT]
                        ),
                        temperature: parseFloat(
                          entry.extensions[FIRST_ELEMENT][extNS][FIRST_ELEMENT][
                            tempNS
                          ][FIRST_ELEMENT]
                        ),
                        */
                      };
                      // Calculate distance
                      if (previous && previous.location && record.location) {
                        // If previous record exists, calculate distance
                        const location: GPSLocation = new GPSLocation();
                        location.setLocation(
                          record.location.latitude,
                          record.location.longitude
                        );
                        const distance: number = location.getDistance(
                          previous.location.latitude,
                          previous.location.longitude
                        );
                        if (previous.distance) {
                          record.distance = previous.distance + distance;
                        } else {
                          record.distance = distance;
                        }
                        // Delta T in second
                        const deltaT: number =
                          Math.floor(record.time.getTime() / 1000) -
                          Math.floor(previous.time.getTime() / 1000);
                        const speed: number = distance / deltaT;
                        record.speed = speed;
                      }
                      if (
                        record.distance !== undefined &&
                        record.elevation &&
                        record.hr &&
                        record.location &&
                        record.time
                      ) {
                        previous = record;
                        records.push(record);
                      }
                    }
                    resolve(records);
                  } else if (result.TrainingCenterDatabase) {
                    // ========================================================
                    //  Reads TCX file
                    // ========================================================
                    if (DEBUG) {
                      console.log("This is a TCX file");
                    }
                    // Gets extension name space
                    const header = result.TrainingCenterDatabase.$;
                    for (const key in header) {
                      const value = header[key] as any;
                      if (
                        key.indexOf(CONSTANT.TAG_NS_EXT) === 0 &&
                        value.indexOf(CONSTANT.TAG_EXT_TCX) !== -1
                      ) {
                        extNameSpace = key.split(CONSTANT.NAME_SPACE_SEPARATOR)[
                          OBJECT_VALUE
                        ];
                      }
                    }
                    const lapData =
                      result.TrainingCenterDatabase.Activities[FIRST_ELEMENT]
                        .Activity[FIRST_ELEMENT].Lap;
                    for (let i = 0; i < lapData.length; i++) {
                      const lap = lapData[i];
                      for (
                        let j = 0;
                        j < lap.Track[FIRST_ELEMENT].Trackpoint.length;
                        j++
                      ) {
                        const entry = lap.Track[FIRST_ELEMENT].Trackpoint[j];
                        const extNS: string =
                          extNameSpace +
                          CONSTANT.NAME_SPACE_SEPARATOR +
                          CONSTANT.TAG_EXT_TCX_TKPT;
                        const speedNS: string =
                          extNameSpace +
                          CONSTANT.NAME_SPACE_SEPARATOR +
                          CONSTANT.TAG_EXT_TCX_SPEED;
                        const cadenceNS: string =
                          extNameSpace +
                          CONSTANT.NAME_SPACE_SEPARATOR +
                          CONSTANT.TAG_EXT_TCX_RUN_CADENCE;
                        const record: TYPE.TrackingData = {
                          elevation: parseFloat(
                            entry.AltitudeMeters[FIRST_ELEMENT]
                          ),
                          distance: parseFloat(
                            entry.DistanceMeters[FIRST_ELEMENT]
                          ),
                          speed: parseFloat(
                            entry.Extensions[FIRST_ELEMENT][extNS][
                              FIRST_ELEMENT
                            ][speedNS][FIRST_ELEMENT]
                          ),
                          runcadence: parseFloat(
                            entry.Extensions[FIRST_ELEMENT][extNS][
                              FIRST_ELEMENT
                            ][cadenceNS][FIRST_ELEMENT]
                          ),
                          hr: parseFloat(
                            entry.HeartRateBpm[FIRST_ELEMENT].Value[
                              FIRST_ELEMENT
                            ]
                          ),
                          location: {
                            latitude: parseFloat(
                              entry.Position[FIRST_ELEMENT].LatitudeDegrees[
                                FIRST_ELEMENT
                              ]
                            ),
                            longitude: parseFloat(
                              entry.Position[FIRST_ELEMENT].LongitudeDegrees[
                                FIRST_ELEMENT
                              ]
                            ),
                          },
                          temperature: undefined,
                          time: new Date(entry.Time[FIRST_ELEMENT]),
                        };
                        if (DEBUG) {
                          console.log(record);
                        }
                        if (
                          record.distance &&
                          record.elevation &&
                          record.hr &&
                          record.location &&
                          record.time
                        ) {
                          records.push(record);
                        }
                      }
                    }
                    resolve(records);
                  }
                }
              }
            );
          }
        } else {
          // "e.target" is null or undefined -- error
          resolve([]);
        }
      };
      reader.onerror = (error: ProgressEvent<FileReader>) => {
        resolve([]);
      };
    });
  }
  analyzeStatistics(
    data: Array<TYPE.DeltaData>,
    minEle: number | undefined,
    maxEle: number | undefined,
    type: string
  ): TYPE.TrackingStats {
    const total: TYPE.Total = {
      distance: 0,
      ascending: 0,
      descending: 0,
    };
    const average: TYPE.Average = {
      speed: 0,
      speedMove: 0,
      vspeed: 0,
      vspeedMove: 0,
      aspeed: 0, //Ascending speed
      hr: 0,
      hrRest: 0,
      hrMove: 0,
      angle: 0,
    };
    const elevation: TYPE.Elevation = {
      max: 0,
      min: 10000,
    };
    const maxHr: TYPE.HeartRate = {
      hr: 0,
      hrRest: 0,
      hrMove: 0,
    };
    const minHr: TYPE.HeartRate = {
      hr: this.userInput.maxHr ? this.userInput.maxHr : CONSTANT.DEFAULT_MAX_HR,
      hrRest: this.userInput.maxHr
        ? this.userInput.maxHr
        : CONSTANT.DEFAULT_MAX_HR,
      hrMove: this.userInput.maxHr
        ? this.userInput.maxHr
        : CONSTANT.DEFAULT_MAX_HR,
    };

    // HR zone
    const hrZone: TYPE.HRzone = {
      zone0: 0,
      zone1: 0,
      zone2: 0,
      zone3: 0,
      zone4: 0,
      zone5: 0,
    };
    const totalTime: TYPE.TotalTime = {
      time: 0,
      move: 0,
      rest: 0,
    };
    const sum: TYPE.Average = {
      hr: 0,
      hrRest: 0,
      hrMove: 0,
      speed: 0,
      speedMove: 0,
      vspeed: 0,
      vspeedMove: 0,
      aspeed: 0,
      angle: 0,
    };
    const samples: TYPE.Samples = {
      all: 0,
      rest: 0,
      move: 0,
      angle: 0,
    };
    const stats: TYPE.TrackingStats = {
      id: minEle ? minEle : 0,
      total: total,
      average: average,
      sum: sum,
      samples: samples,
      elevation: elevation,
      minHR: minHr,
      maxHR: maxHr,
      hrZone: hrZone,
      totalTime: totalTime,
    };
    let hrr = false;
    for (let i = 0; i < data.length; i++) {
      const rec: TYPE.DeltaData = data[i];
      if (
        (minEle &&
          minEle < rec.data.elevation &&
          maxEle &&
          maxEle >= rec.data.elevation) ||
        (minEle === undefined && maxEle === undefined)
      ) {
        // Distance information
        total.distance += rec.distance;
        if (rec.elevation < 0) {
          // Descending
          total.descending += rec.elevation;
        } else if (rec.elevation > 0) {
          // Ascending
          total.ascending += rec.elevation;
        }
        if (type === CONSTANT.ANALYSIS_TYPE_ASCEND) {
          total.descending = 0;
          if (rec.elevation > 0) {
            totalTime.time += rec.time;
            sum.vspeed += rec.elevation / rec.time;
            sum.speed += rec.distance / rec.time;
            sum.hr += rec.data.hr;
            //this.total_ascending	+=	rec['deltaA'];
            if (rec.distance !== 0) {
              sum.angle += Math.atan(Math.abs(rec.elevation / rec.distance));
              samples.angle++;
            }
            if (
              this.userInput.maxSpeedRest &&
              rec.distance / rec.time < this.userInput.maxSpeedRest
            ) {
              // Resting
              totalTime.rest += rec.time;
              sum.hrRest += rec.data.hr;
              samples.rest++;

              if (maxHr.hrRest < rec.data.hr) {
                maxHr.hrRest = rec.data.hr;
              }
              if (minHr.hrRest > rec.data.hr) {
                minHr.hrRest = rec.data.hr;
              }
            } else {
              // Moving
              totalTime.move += rec.time;
              sum.hrMove += rec.data.hr;
              samples.move++;
              sum.speedMove += rec.distance / rec.time;
              sum.vspeedMove += rec.elevation / rec.time;
              if (maxHr.hrMove < rec.data.hr) {
                maxHr.hrMove = rec.data.hr;
              }
              if (minHr.hrMove > rec.data.hr) {
                minHr.hrMove = rec.data.hr;
              }
            }
            if (maxHr.hr < rec.data.hr) {
              maxHr.hr = rec.data.hr;
            }
            if (minHr.hr > rec.data.hr) {
              minHr.hr = rec.data.hr;
            }
            if (!this.userInput.restHr) {
              hrr = false;
            } else {
              hrr = true;
            }
            const zone: number = this.getHrZone(
              rec.data.hr,
              this.userInput.age as number,
              this.userInput.restHr,
              this.userInput.maxHr,
              hrr
            );
            if (zone < 1) {
              hrZone.zone0 += rec.time;
            } else if (1 <= zone && zone < 2) {
              hrZone.zone1 += rec.time;
            } else if (2 <= zone && zone < 3) {
              hrZone.zone2 += rec.time;
            } else if (3 <= zone && zone < 4) {
              hrZone.zone3 += rec.time;
            } else if (4 <= zone && zone < 5) {
              hrZone.zone4 += rec.time;
            } else if (5 <= zone) {
              hrZone.zone5 += rec.time;
            }
            if (elevation.max < rec.data.elevation) {
              elevation.max = rec.data.elevation;
            }
            if (elevation.min > rec.data.elevation) {
              elevation.min = rec.data.elevation;
            }
            samples.all++;
          }
        } else if (type === CONSTANT.ANALYSIS_TYPE_DESCEND) {
          total.ascending = 0;
          if (rec.elevation < 0) {
            totalTime.time += rec.time;
            sum.vspeed += rec.elevation / rec.time;
            sum.speed += rec.distance / rec.time;
            sum.hr += rec.data.hr;
            //this.total_descending	+=	rec['deltaA'];
            if (rec.distance !== 0) {
              sum.angle += Math.atan(Math.abs(rec.elevation / rec.distance));
              samples.angle++;
            }
            if (
              this.userInput.maxSpeedRest &&
              rec.distance / rec.time < this.userInput.maxSpeedRest
            ) {
              totalTime.rest += rec.time;
              sum.hrRest += rec.data.hr;
              samples.rest++;

              if (maxHr.hrRest < rec.data.hr) {
                maxHr.hrRest = rec.data.hr;
              }
              if (minHr.hrRest > rec.data.hr) {
                minHr.hrRest = rec.data.hr;
              }
            } else {
              totalTime.move += rec.time;
              sum.hrMove += rec.data.hr;
              samples.move++;
              sum.speedMove += rec.distance / rec.time;
              sum.vspeedMove += rec.elevation / rec.time;
              if (maxHr.hrMove < rec.data.hr) {
                maxHr.hrMove = rec.data.hr;
              }
              if (minHr.hrMove > rec.data.hr) {
                minHr.hrMove = rec.data.hr;
              }
            }
            if (maxHr.hr < rec.data.hr) {
              maxHr.hr = rec.data.hr;
            }
            if (minHr.hr > rec.data.hr) {
              minHr.hr = rec.data.hr;
            }
            if (!this.userInput.restHr) {
              hrr = false;
            } else {
              hrr = true;
            }
            const zone: number = this.getHrZone(
              rec.data.hr,
              this.userInput.age as number,
              this.userInput.restHr,
              this.userInput.maxHr,
              hrr
            );
            if (zone < 1) {
              hrZone.zone0 += rec.time;
            } else if (1 <= zone && zone < 2) {
              hrZone.zone1 += rec.time;
            } else if (2 <= zone && zone < 3) {
              hrZone.zone2 += rec.time;
            } else if (3 <= zone && zone < 4) {
              hrZone.zone3 += rec.time;
            } else if (4 <= zone && zone < 5) {
              hrZone.zone4 += rec.time;
            } else if (5 <= zone) {
              hrZone.zone5 += rec.time;
            }
            if (elevation.max < rec.data.elevation) {
              elevation.max = rec.data.elevation;
            }
            if (elevation.min > rec.data.elevation) {
              elevation.min = rec.data.elevation;
            }
            samples.all++;
          }
        } else if (type === CONSTANT.ANALYSIS_TYPE_TOTAL) {
          totalTime.time += rec.time;
          sum.vspeed += rec.elevation / rec.time;
          sum.speed += rec.distance / rec.time;
          sum.hr += rec.data.hr;
          if (rec.distance !== 0) {
            sum.angle += Math.atan(Math.abs(rec.elevation / rec.distance));
            samples.angle++;
          }
          if (
            this.userInput.maxSpeedRest &&
            rec.distance / rec.time < this.userInput.maxSpeedRest
          ) {
            totalTime.rest += rec.time;
            sum.hrRest += rec.data.hr;
            samples.rest++;

            if (maxHr.hrRest < rec.data.hr) {
              maxHr.hrRest = rec.data.hr;
            }
            if (minHr.hrRest > rec.data.hr) {
              minHr.hrRest = rec.data.hr;
            }
          } else {
            totalTime.move += rec.time;
            sum.hrMove += rec.data.hr;
            samples.move++;
            sum.speedMove += rec.distance / rec.time;
            sum.vspeedMove += rec.elevation / rec.time;
            if (maxHr.hrMove < rec.data.hr) {
              maxHr.hrMove = rec.data.hr;
            }
            if (minHr.hrMove > rec.data.hr) {
              minHr.hrMove = rec.data.hr;
            }
          }
          if (maxHr.hr < rec.data.hr) {
            maxHr.hr = rec.data.hr;
          }
          if (minHr.hr > rec.data.hr) {
            minHr.hr = rec.data.hr;
          }
          if (!this.userInput.restHr) {
            hrr = false;
          } else {
            hrr = true;
          }
          const zone: number = this.getHrZone(
            rec.data.hr,
            this.userInput.age as number,
            this.userInput.restHr,
            this.userInput.maxHr,
            hrr
          );
          if (zone < 1) {
            hrZone.zone0 += rec.time;
          } else if (1 <= zone && zone < 2) {
            hrZone.zone1 += rec.time;
          } else if (2 <= zone && zone < 3) {
            hrZone.zone2 += rec.time;
          } else if (3 <= zone && zone < 4) {
            hrZone.zone3 += rec.time;
          } else if (4 <= zone && zone < 5) {
            hrZone.zone4 += rec.time;
          } else if (5 <= zone) {
            hrZone.zone5 += rec.time;
          }
          if (elevation.max < rec.data.elevation) {
            elevation.max = rec.data.elevation;
          }
          if (elevation.min > rec.data.elevation) {
            elevation.min = rec.data.elevation;
          }
          samples.all++;
        } else {
          // Error -- ignore this record
          continue;
        }
        if (rec.elevation > 0) {
          sum.aspeed += rec.distance / rec.time;
        }
      }
    }
    average.hr = sum.hr / samples.all;
    average.hrRest = sum.hrRest / samples.rest;
    average.hrMove = sum.hrMove / samples.move;
    average.speed = sum.speed / samples.all;
    average.speedMove = sum.speedMove / samples.move;

    average.vspeed = sum.vspeed / samples.all;
    average.vspeedMove = sum.vspeedMove / samples.move;
    average.aspeed = sum.aspeed / samples.all;
    average.angle = ((sum.angle / samples.angle) * 180) / Math.PI;
    return stats;
  }
  static setHrZone(
    age: number,
    min: number | undefined,
    max: number | undefined
  ): TYPE.CalculatedHrZone {
    /** Maximum heart rate */
    let maxHr: number;
    /** Minimum heart rate */
    let minHr: number;
    /** Heart rate reserved */
    let hrr: boolean;
    /** Heart rate reserved range */
    let hrrRange: number;
    /** Minimum hr of a zone */
    //let minSide;
    /** Maximum hr of a zone */
    //let maxSide;
    // Check maximum heart rate
    const zone = {
      zone0Min: 0,
      zone0Max: 0,
      zone1Min: 0,
      zone1Max: 0,
      zone2Min: 0,
      zone2Max: 0,
      zone3Min: 0,
      zone3Max: 0,
      zone4Min: 0,
      zone4Max: 0,
      zone5Min: 0,
      zone5Max: 0,
      upperlimit: 0,
    };
    if (!max) {
      // Age must be integer and it must be checked before calling this function (no error handing in this function)
      maxHr = 220 - age;
    } else {
      maxHr = max;
    }
    if (!min) {
      hrr = false;
      hrrRange = 0;
      minHr = 0;
    } else {
      hrr = true;
      minHr = min;
      hrrRange = maxHr - minHr;
    }
    // Zone 5 (more than 90%)
    if (hrr) {
      zone.zone5Min = parseInt(String(0.9 * hrrRange + minHr));
    } else {
      zone.zone5Min = parseInt(String(0.9 * maxHr));
    }
    // Zone 4 (more than 80%)
    zone.zone4Max = zone.zone5Min - 1;
    if (hrr) {
      zone.zone4Min = parseInt(String(0.8 * hrrRange + minHr));
    } else {
      zone.zone4Min = parseInt(String(0.8 * maxHr));
    }
    // Zone 3 (more than 70%)
    zone.zone3Max = zone.zone4Min - 1;
    if (hrr) {
      zone.zone3Min = parseInt(String(0.7 * hrrRange + minHr));
    } else {
      zone.zone3Min = parseInt(String(0.7 * maxHr));
    }
    // Zone 2 (more than 60%)
    zone.zone2Max = zone.zone3Min - 1;
    if (hrr) {
      zone.zone2Min = parseInt(String(0.6 * hrrRange + minHr));
    } else {
      zone.zone2Min = parseInt(String(0.6 * maxHr));
    }

    // Zone 1 (more than 50%)
    zone.zone1Max = zone.zone2Min - 1;
    if (hrr) {
      zone.zone1Min = parseInt(String(0.5 * hrrRange + minHr));
    } else {
      zone.zone1Min = parseInt(String(0.5 * maxHr));
    }
    // Zone 0 (less than 50%, does not include 50%)
    zone.zone0Max = zone.zone1Min - 1;
    // Sets recommended target hr

    if (hrr) {
      zone.upperlimit = parseInt(String(0.75 * hrrRange + minHr));
    } else {
      zone.upperlimit = parseInt(String(0.75 * maxHr));
    }
    return zone;
  }
  static getHeartRate(
    zone: number,
    upper: boolean,
    age: number,
    min: number | undefined,
    max: number | undefined
  ): number {
    let maxHr: number;
    let minHr: number;
    let hrRange: number;
    let hrr: boolean;
    let hr: number;
    if (max) {
      maxHr = max;
    } else {
      maxHr = 220 - age;
    }
    if (min) {
      hrr = true;
      minHr = min;
      hrRange = maxHr - minHr;
    } else {
      hrr = false;
      minHr = 0;
      hrRange = 0;
    }
    if (hrr) {
      if (upper) {
        hr = parseInt(String((zone / 10 + 0.5) * hrRange + minHr)) - 1;
      } else {
        hr = parseInt(String((zone / 10 + 0.4) * hrRange + minHr));
      }
    } else {
      if (upper) {
        hr = parseInt(String((zone / 10 + 0.5) * maxHr)) - 1;
      } else {
        hr = parseInt(String((zone / 10 + 0.4) * maxHr));
      }
    }
    return hr;
  }

  // ========================================================================
  //	HR reated operation
  // ========================================================================
  /** Gets HR zone
   *	@param	hr	Heart rate (integer) - (*) Required
   *	@param	age	Age (integer) - (*)Required
   *	@param	max	Maximum Heart rate (integer) - (*) Optional, can be ""
   *	@param	min	Minimum (Resting) Heart rate (integer) - (*) Optional, can be ""
   *	@param	hrr	Heart rate zoen type (boolean) true: HRR (HR Reserved base), false: MHR (Max HR base)
   *	@return Returns heart rate zone (double)
   *	@throw	Throws Exception if hr or age is NaN
   */
  getHrZone(
    hr: number,
    age: number,
    min: number | undefined,
    max: number | undefined,
    hrr: boolean
  ): number {
    let maxHr: number;
    let ratio: number;
    if (!max) {
      maxHr = 220 - age;
    } else {
      maxHr = max;
    }
    if (min && hrr) {
      // HRR base
      const rr: number = maxHr - min;
      ratio = (hr - min) / rr;
    } else {
      // MHR base
      ratio = hr / maxHr;
    }
    const hrZone: number = (ratio - 0.4) * 10;
    return hrZone;
  }
}
export default XML;
