import * as React from "react";
import "./Styles/home.css";
import "./Styles/markdown.css";
class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="home">
          <h1 className="default_page_title">Weekend Chronicle</h1>
          <p>
            Weekend
            Chronicleはアメリカ合衆国カリフォルニア州のシリコンバレーから
            お届けする登山・ハイキング・キャンピングの情報サイトです！
          </p>
          <h2>登山を快適にするスマートウォッチの利用法</h2>
          <p>
            最近はアップルウォッチをはじめとして、スマートウォッチの普及が進んでいます。
          </p>
          <p>
            最新のスマートウォッチは心拍数をリアルタイムでモニタする機能がついていて、
            ランナーはこの機能を利用して、マラソンを走る際にペース管理の指標にしています。
            実は、この機能は登山やハイキングでも役立てる事ができます。
          </p>
          <p>
            とくに標高3000mを超える場所での登山やハイキングでは、高山病の自覚症状がでるまでには
            時間差があって、知らず知らずの内にオーバーペースになってしまうことが多くなります。
            心拍計はこうした、標高の体への影響をいち早く察知するのに大いに役立ちます。
          </p>
          <p>
            マラソンランナーとハイカーとの経験を生かして、登山における心拍計の有効活用法を
            配信しています。
          </p>
          <h2>Mt.Whitney情報</h2>
          <p>
            Mt.
            Whitneyはカリフォルニア州の頭部にある山で、カリフォルニア州の最高峰です。
            標高は4,421mで、アラスカの山を除くと、アメリカ本土の48州の中では最高峰になります。
            気軽に登れるため、ハイカーからの人気も高く、冬季を除く期間に登山するには
            毎年抽選を経て登山許可が必要になります。
          </p>
          <p>
            このページでは、カリフォルニア州シリコンバレー在住という事を生かして、現地の情報や
            登山事情などをお伝えしています。
          </p>

          <h2>シリコンバレー近郊のキャンプ情報情報</h2>
          <p>現在準備中です！しばらくお待ちください</p>
        </div>
      </React.Fragment>
    );
  }
}
export default Home;
