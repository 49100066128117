export const DEFAULT_AGE = 30;
export const DEFAULT_REST_MAX_SPEED = 0.3;
export const DEFAULT_ANALYSIS_INTERVAL = 100;

/** Default maximum heard rate */
export const DEFAULT_MAX_HR = 180;

/** Invalid data */
export const INVALID = -1;
/** Analysis type: total */
export const ANALYSIS_TYPE_TOTAL = "total";
/** Analysis type: ascend */
export const ANALYSIS_TYPE_ASCEND = "ascend";
/** Analysis type: descend */
export const ANALYSIS_TYPE_DESCEND = "descend";

/** Tag for name space definition */
export const TAG_NS_EXT = "xmlns";
/** Name space seperator */
export const NAME_SPACE_SEPARATOR = ":";

/** Tag for GPX file */
export const TAG_GPX = "gpx";
/** Tag for GPX extension */
export const TAG_EXT_GPX = "TrackPointExtension";
/** Tag for HR (TrackPointExtension value) */
export const TAG_EXT_GPX_HR = "hr";
/** Tag for Run Cadence (TrackPointExtension value) */
export const TAG_EXT_GPX_RUN_CADENCE = "cad";
/** Tag for Temperature (TrackPointExtension value) */
export const TAG_EXT_GPX_TEMP = "atemp";

/** Tag for TCX file */
export const TAG_TCX = "TrainingCenterDatabase";
/** Tag for TCX extension */
export const TAG_EXT_TCX = "ActivityExtension";
/** TAG for TCX trackpoint extension */
export const TAG_EXT_TCX_TKPT = "TPX";
/** TAG for Speed (Tracking point extension value) */
export const TAG_EXT_TCX_SPEED = "Speed";
/** TAG for Run Cadence (Tracking point extension value) */
export const TAG_EXT_TCX_RUN_CADENCE = "RunCadence";
