import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../Redux/Reducers/index";
import XML from "../Lib/xml";
interface IState {}
interface IProps {}
type ReduxType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProps;
class HrZoneTable extends React.Component<ReduxType, IState> {
  render() {
    if (this.props.age) {
      return (
        <React.Fragment>
          <div>
            <table className="table table-sm">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">心拍ゾーン</th>
                  <th scope="col">心拍数の範囲</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Zone 0</td>
                  <td>
                    <input type="text" value="''" readOnly />
                    ～
                    <input
                      type="text"
                      value={XML.getHeartRate(
                        0,
                        true,
                        this.props.age,
                        this.props.resthr,
                        this.props.maxhr
                      )}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <td>Zone 1</td>
                  <td>
                    <input
                      type="text"
                      value={XML.getHeartRate(
                        1,
                        false,
                        this.props.age,
                        this.props.resthr,
                        this.props.maxhr
                      )}
                      readOnly
                    />
                    ～
                    <input
                      type="text"
                      value={XML.getHeartRate(
                        1,
                        true,
                        this.props.age,
                        this.props.resthr,
                        this.props.maxhr
                      )}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <td>Zone 2</td>
                  <td>
                    <input
                      type="text"
                      value={XML.getHeartRate(
                        2,
                        false,
                        this.props.age,
                        this.props.resthr,
                        this.props.maxhr
                      )}
                      readOnly
                    />
                    ～
                    <input
                      type="text"
                      value={XML.getHeartRate(
                        2,
                        true,
                        this.props.age,
                        this.props.resthr,
                        this.props.maxhr
                      )}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <td>Zone 3</td>
                  <td>
                    <input
                      type="text"
                      value={XML.getHeartRate(
                        3,
                        false,
                        this.props.age,
                        this.props.resthr,
                        this.props.maxhr
                      )}
                      readOnly
                    />
                    ～
                    <input
                      type="text"
                      value={XML.getHeartRate(
                        3,
                        true,
                        this.props.age,
                        this.props.resthr,
                        this.props.maxhr
                      )}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <td>Zone 4</td>
                  <td>
                    <input
                      type="text"
                      value={XML.getHeartRate(
                        4,
                        false,
                        this.props.age,
                        this.props.resthr,
                        this.props.maxhr
                      )}
                      readOnly
                    />
                    ～
                    <input
                      type="text"
                      value={XML.getHeartRate(
                        4,
                        true,
                        this.props.age,
                        this.props.resthr,
                        this.props.maxhr
                      )}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <td>Zone 5</td>
                  <td>
                    <input
                      type="text"
                      value={XML.getHeartRate(
                        5,
                        false,
                        this.props.age,
                        this.props.resthr,
                        this.props.maxhr
                      )}
                      readOnly
                    />
                    ～
                    <input type="text" value="''" readOnly />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </React.Fragment>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    age: state.gpsReducer.age,
    resthr: state.gpsReducer.resthr,
    maxhr: state.gpsReducer.maxhr,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(HrZoneTable);
