import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { RootReducer } from "./Reducers/index";
const initialState = {};
const middleware = [thunk];
let store: any;
console.log(process.env.REACT_APP_PRODUCTION);
if (process.env.REACT_APP_PRODUCTION === "true") {
  store = createStore(
    RootReducer,
    initialState,
    compose(applyMiddleware(...middleware))
  );
} else {
  store = createStore(
    RootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
}
export default store;
